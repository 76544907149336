









































































import { Component, Prop, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import { Getter } from "vuex-class";
import { Api } from "@/api";
import { AreYouSureModal, InfoModal } from "@/components";
import { namespace as authNs } from "@/store/auth";

@Component({
  components: {
    AreYouSureModal,
    InfoModal,
  },
  mixins: [validationMixin],
  validations: {
    newEmail: { required, email },
  },
})
export default class Settings extends Vue {
  @Getter("email", { namespace: authNs }) currentEmail!: string | null;
  @Prop({ type: String, required: false }) private params!: string;

  private areYouSureToTerminateVisible = false;
  private areYouSureToUpdateVisible = false;

  private infoModalVisible = false;
  private infoModalProps = { title: "" };

  private newEmail = "";

  private async mounted(): Promise<void> {
    if (this.params) {
      await this.confirmEmailUpdate();
    }
  }

  private async confirmEmailUpdate(): Promise<void> {
    const [email, message, sig] = this.params.split("::");
    const signatureBase64: string = decodeURI(sig);
    await this.$store.dispatch(`${authNs}/confirmUpdateEmail`, {
      email,
      message,
      signatureBase64,
    });
  }

  private async updateEmailConfirmed(): Promise<void> {
    if (this.validNewEmail) {
      try {
        await Api.updateEmail({ email: this.newEmail });
        this.showInfoModalWithText(
          "Please check your mailbox, we sent you a confirmation email."
        );
      } catch (error) {
        this.showInfoModalWithText("This email is already been taken.");
      }

      this.areYouSureToUpdateVisible = false;
      this.$v.$reset();
      this.newEmail = "";
    }
  }

  private async terminateConfirmed(): Promise<void> {
    try {
      await Api.terminateAccount();
      this.$router.push({ name: "signup" });
    } catch (e) {
      console.error(e);
    }
  }

  private get validNewEmail(): boolean | null {
    if (this.$v.$dirty) {
      return this.validateNewEmail();
    }
    return null;
  }

  private validateNewEmail(): boolean {
    const valid = !this.$v.newEmail.$invalid;
    return valid;
  }

  private onTerminateButtonClick(): void {
    this.areYouSureToTerminateVisible = true;
  }

  private async onUpdateEmailButtonClick(): Promise<void> {
    this.$v.$touch();
    if (this.validNewEmail) {
      this.areYouSureToUpdateVisible = true;
    }
  }

  private showInfoModalWithText(text: string) {
    this.infoModalProps.title = text;
    this.infoModalVisible = true;
  }
}
